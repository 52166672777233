import { MapboxAddressMinimap } from '@mapbox/search-js-web';

export default function (Alpine) {
    Alpine.directive('minimap', minimap)

    function minimap(el, { expression }, { evaluateLater, effect }) {
        let updateOnEffect = true;

        let minimap = mapboxInstance();
        let expressionData = evaluateLater(expression);

        el.appendChild(minimap);
        minimap.onSaveMarkerLocation = (coordinates) => {
            updateOnEffect = false;

            el.dispatchEvent(
                new CustomEvent('map:updated', {
                    detail: { 'longitude': coordinates[0], 'latitude': coordinates[1] },
                    bubbles: true,
                    composed: true,
                    cancelable: true,
                })
            );
        };

        effect(() => {
            expressionData((data) => {
                if (typeof data.preview === 'boolean') {
                    minimap.canAdjustMarker = ! data.preview;
                }

                // Skip update when the map already displays the coordinates
                // or when the current evaluation should be ignored.
                if ((
                    typeof minimap.feature !== 'undefined'
                    && minimap.feature.geometry.coordinates[0] === data.longitude
                    && minimap.feature.geometry.coordinates[1] === data.latitude
                ) || ! updateOnEffect) {
                    updateOnEffect = true;
                    return;
                }

                setCoordinates(minimap, data.longitude, data.latitude)
            });
        });
    }
}

function mapboxInstance() {
    const minimap = new MapboxAddressMinimap()
    const icons = {
        marker: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width=".5" stroke="rgba(0,0,0,0.2)" fill="none" class="w-12 h-12 drop-shadow-lg">
            <path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            <path fill="#13a4ea" stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
        `
      }

    minimap.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
    minimap.keepMarkerCentered = true;
    minimap.canAdjustMarker = true;
    // minimap.defaultMapStyle = ['distortedfusion', 'clox0rfqz010x01qo0ctd18hf']
    minimap.theme = {
        icons: icons
    }

    return minimap;
}

function setCoordinates(minimap, longitude, latitude) {
    minimap.feature = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [longitude, latitude]
        },
        properties: {}
    };
}

function getValueFromExpressionData(data, key, optional = false) {
    if (! data.hasOwnProperty(key) && optional) {
        return null;
    }

    if (! data.hasOwnProperty(key)) {
        throwError(key);
    }

    const rawValue = data[key];

    if ((rawValue === undefined || rawValue === null) && ! optional) {
        throwError(key);
    }

    return rawValue;
}

function throwError() {
    throw new Error('Missing expression for x-minimap directive, both x-minimap.latitude and x-minimap.longitude must be provided and can not be empty.')
}
