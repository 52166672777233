import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding-v6';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import flatpickr from "flatpickr";
import minimap from './alpinejs/minimap';


window.MapboxGeocoding = mbxGeocoding({
    accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
});

window.flatpickr = flatpickr;

Alpine.plugin(minimap);

Livewire.start()
